import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, CoUserSsoService } from '@safarilaw-webapp/shared/auth';
import { HTTP_STATUS_CODE_API_NOTFOUND, HTTP_STATUS_CODE_API_VALIDATION } from '@safarilaw-webapp/shared/common-objects-models';
import { Subscription, of, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

@Component({
  selector: 'sl-root-co-user-sso-init',
  templateUrl: './co-user-sso-init.component.html',
  styleUrls: ['./co-user-sso-init.component.scss']
})
export class CoUserSsoInitComponent implements OnInit, OnDestroy {
  constructor(
    private _ssoService: CoUserSsoService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  private _mainSubscription: Subscription;

  ngOnInit() {
    /* Fire this as soon as the component initializes */
    this.doUserSsoSetup();
  }

  ngOnDestroy() {
    if (this._mainSubscription) {
      this._mainSubscription.unsubscribe();
    }
  }

  doUserSsoSetup() {
    this._mainSubscription = this._ssoService
      .getSsoUser()
      .pipe(
        concatMap(o => this._ssoService.hookupSsoUser(o.id)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === HTTP_STATUS_CODE_API_VALIDATION && error.error.error.message === "The current user's authorization is already setup") {
            return of(null);
          } else if (error.status === HTTP_STATUS_CODE_API_NOTFOUND) {
            // add a delay just so we don't flash the page super fast
            setTimeout(() => {
              void this._router.navigateByUrl('login-no-user');
            }, 1500);
            return throwError(() => ({
              ...error,
              silent: true
            }));
          }
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        /* User has been hooked up, so let's send them back home. Use login to force the token to refresh.
         * Clear any existing tokens to ensure we don't reuse one with missing metadata.
         * Adding the connection ID will skip the Auth0 speedbump where users have to type in their email address
         */
        localStorage.clear();
        this._authService.logout(`login?connection=${this._authService.auth0ConnectionClaim}`);
      });
  }
}
