import { Component, OnInit } from '@angular/core';
import { CoManageHelper } from '@safarilaw-webapp/feature/co-manage/data-access';
import { AuthService, UserVerificationService } from '@safarilaw-webapp/shared/auth';
import { UserCompany } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';

@Component({
  selector: 'sl-root-co-user-select-company',
  templateUrl: './co-user-select-company.component.html',
  styleUrls: ['./co-user-select-company.component.scss']
})
export class CoUserSelectCompanyComponent implements OnInit {
  searchCriteria: string;

  get companyList() {
    const filteredCompanies: UserCompany[] = this._authService.currentUserCompanies?.filter(o => o.isActive) ?? [];
    if (!String.isNullOrEmpty(this.searchCriteria)) {
      const lowerSearchCriteria = this.searchCriteria.toLowerCase();
      return filteredCompanies.filter(o => o.name.toLowerCase().includes(lowerSearchCriteria));
    }
    return filteredCompanies;
  }
  redirectTargetRoute: string;

  constructor(private _userVerificationService: UserVerificationService, private _authService: AuthService, private _appConfigurationService: AppConfigurationService) {}

  ngOnInit(): void {
    this.redirectTargetRoute = history.state.redirectTargetRoute;
  }

  selectCompany(companyId: string) {
    this._authService.selectCompany(companyId, this.redirectTargetRoute);
  }

  getCompanyLogoUrl(companyId: string): string {
    return CoManageHelper.getCompanyLogoUrl(this._appConfigurationService, companyId);
  }
}
