import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector -- don't want slUiKit requirement
  selector: '[noMultiClick]',
  standalone: true
})
export class NoMultiClickDirective implements OnInit, OnDestroy {
  @Input()
  throttleTime = 500;

  @Input()
  disableOnThrottle = false;

  @Output()
  throttledClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(
        tap(() => this._elementRef.nativeElement.classList.add('s_throttled')),
        throttleTime(this.throttleTime)
      )
      .subscribe(e => {
        this.emitThrottledClick(e);
        setTimeout(() => {
          this._elementRef.nativeElement.classList.remove('s_throttled');
          if (this.disableOnThrottle) {
            this._elementRef.nativeElement.removeAttribute('disabled');
          }
        }, this.throttleTime);
      });
  }

  emitThrottledClick(e) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disableOnThrottle) {
      this._elementRef.nativeElement.setAttribute('disabled', 'disabled');
    }
    this.clicks.next(event);
  }
}
