import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationInsightsService } from '@safarilaw-webapp/shared/logging';

@Component({
  templateUrl: './redirector.component.html'
})
export class RedirectorComponent implements OnInit {
  constructor(private _route: ActivatedRoute, private _router: Router, private _appInsights: ApplicationInsightsService) {}
  ngOnInit(): void {
    // Take the path we want to redirect to
    // This must have the SAME structure as the original path and it has to be abosolute
    // So for example if we are redirecting from subtypes/edit/:id or subtypes/list
    // The path will be legal-process/subtypes-new/edit/:id or subtypes-new/list-new, etc
    const newPath: string[] = this._route.snapshot.data['path'].split('/');
    // Also grab any query params that might be there
    const queryParams = this._route.snapshot.queryParams;
    // Now we're going to break up the CURRENT path into urlSegments. So something like
    // subtypes/edit/324343 will give us an array ['subtypes', 'edit', '324343']
    const urlSegments = this._route.snapshot.url;

    let originalPathIndex = newPath.length - 1;
    // Backtrack from the end of the current path and with each step either take the
    // part of the new path OR use the part from the current path (ID param, etc)
    for (let i = urlSegments.length - 1; i >= 0; i--) {
      // If what we have at newPath starts with colon then this is a dynamic parameter so just
      // take it from the current path. Otherwise keep the newPath entry
      if (newPath[originalPathIndex].startsWith(':')) {
        newPath[originalPathIndex] = urlSegments[i].toString();
      }
      originalPathIndex--;
    }
    // Now rejoin it with / and forward
    const newPathFinal = newPath.join('/');

    this._appInsights.trackEvent('RedirectorComponent', {
      path: newPathFinal
    });

    setTimeout(() => {
      this._router.navigate([newPathFinal], { queryParams }).catch(err => {
        throw err;
      });
    }, 5000);
  }
}
