<div class="wrapper">
   <main>
      <header class="navbar">
         <div class="container s_con">
            <div id="s_navleft" class="s_sys-notice">
               <div class="s_con-title">
                  @if(errorMessage) {
                     Safari SOP Update Error
                  } @else {
                     Updating Safari SOP...
                  }

               </div>
               @if(errorMessage) {
                  <hr>
               <div class="text-danger">{{errorMessage}}</div>
               }  

               <hr>
            </div>
         </div>
      </header>
   </main>
</div>