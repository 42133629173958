<div class="wrapper">
    <!-- MAIN -->
    <main class="s_bg-404">
        <div class="row mt-5 justify-content-center">
            <div class="col-md-12 text-center">
                <span _ngcontent-pyt-c125="" class="display-3 d-block">First Time Login</span>
                <div class="mb-4 lead">
                    Connecting your corporate account to Safari...
                    This will only happen once.
                    <br/>
                    <br/>
                    You may be prompted to log in again.
                </div>
            </div>
        </div>
    </main>
</div><!-- END: wrapper -->