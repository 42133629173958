import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthGuardService } from '@safarilaw-webapp/shared/auth';
import {
  AuthCallbackComponent,
  ClearStorageComponent,
  CoUserSelectCompanyComponent,
  CoUserSsoInitComponent,
  GatewayTimeoutComponent,
  InterstitialComponent,
  LoginComponent,
  LoginErrorComponent,
  LoginNoUserComponent,
  LogoutComponent,
  PageErrorComponent,
  PageNotFoundComponent,
  TermsOfServiceComponent,
  UnauthorizedComponent
} from '@safarilaw-webapp/shared/common-pages';
import { CHUNK_RETRY_COUNT_PARAM } from '@safarilaw-webapp/shared/error-handling';
import { environmentImports } from './app.routing.env.imports';
import { InitComponent } from './init/init.component';
const routes: Routes = [
  // This path used to point to "legal-process" which in turn used to load feature module
  // Now we are going to go straight to feature module, and that one internally will have
  // children that represent previous top level URLS (legal-process vs settings)
  {
    path: '',
    loadChildren: () => import('@safarilaw-webapp/feature/lpms/ui').then(m => m.FeatureLpmsModuleUI),
    canActivate: [AuthGuardService]
  },

  ...environmentImports,
  // COPY THIS FOR ALL NEW APPS
  // Routes for shared-common-pages
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
  { path: 'interstitial', component: InterstitialComponent, data: { hideNavbar: true, hideHeader: true, operationName: 'Interstitial' } },
  { path: 'auth-callback', component: AuthCallbackComponent, data: { hideNavbar: false, hideHeader: true, operationName: 'Auth Callback' } },
  { path: 'logout', component: LogoutComponent, data: { hideHeader: true, suppressContainer: true, operationName: 'Logout' } },
  { path: 'clear', component: ClearStorageComponent, data: { operationName: 'Clear Local Storage' } },
  { path: 'login-error', component: LoginErrorComponent, data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - Error' } },
  { path: 'login-no-user', component: LoginNoUserComponent, data: { hideHeader: true, suppressContainer: true, hideNavbar: true, operationName: 'Login - No User' } },
  { path: 'error', component: PageErrorComponent, data: { pageTitle: 'Request Failure', operationName: 'Error' } },
  { path: 'login', component: LoginComponent, data: { hideNavbar: true, hideHeader: true, suppressContainer: true, operationName: 'Login' } },
  { path: '403', component: UnauthorizedComponent, data: { hideHeader: true, suppressContainer: true, operationName: 'Not Authorized' } },
  { path: '404', component: PageNotFoundComponent, data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' } },
  { path: '504', component: GatewayTimeoutComponent, data: { hideHeader: true, suppressContainer: true, operationName: 'Gateway Timeout' } },
  { path: 'termsofservice', component: TermsOfServiceComponent, data: { pageTitle: 'Terms & Privacy', operationName: 'Terms Of Service' } },

  { path: 'sso-init', component: CoUserSsoInitComponent, pathMatch: 'full', canActivate: [AuthGuardService], data: { hideNavbar: true, hideHeader: true, operationName: 'SSO Init' } },
  {
    path: 'select-company',
    component: CoUserSelectCompanyComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    data: { hideNavbar: true, hideHeader: true, operationName: 'Select Company' }
  },
  { path: 'init', component: InitComponent, data: { hideNavbar: true, hideHeader: true } },
  { path: '**', component: PageNotFoundComponent, data: { hideHeader: true, suppressContainer: true, operationName: 'Page Not Found' } }

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////////
];

@NgModule({
  // 'legacy' was auto-added by angular during conversion but we should review if we want that. The new 'corrected'
  // option is the default, but looks like they want to be safe during migration and they add 'legacy'. We will probably need to
  // retest all links if we do so.

  // NAVBAR CHANGE - to rollback delete onSameUrlNavigation parameter
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      preloadingStrategy: AppCustomPreloader,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
export class AppRoutingModule {
  constructor(router: Router) {
    const url = new URL(window.location.href);

    // Don't blindly navigate to init. In normal circumstances the module should never load with
    // "init" as its startup path, but it could if this happened during chunkload error. So if that's
    // the case make sure you pass the chunk count
    if (url.pathname.toLowerCase() == '/init') {
      const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
      if (!isNaN(chunkCount)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- don't want to catch this
        router.navigate(['init'], {
          queryParams: {
            // eslint-disable-next-line @typescript-eslint/naming-convention -- this is correct name
            __chcn: chunkCount
          }
        });
        return;
      }
    }
    // Otherwise this is a normal flow so go to init component but don't change the location
    void router.navigate(['/init'], { skipLocationChange: true });
  }
}
