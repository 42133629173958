import { Action, ActionCreator, DefaultProjectorFn, MemoizedSelector, createAction, props } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { FileOperationActions } from '../enums';
import { BulkFileOperationRequest, ClearFileInfoPayload, FileOperationInfo, FilePreviewRequest, FilePreviewResponse } from './file-operation';

const createCancelTransferAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ abort?: any }>());
};
const createProcessFileFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createProcessFileSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createUpdateFileProgressAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createClearFileInfoAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  // TODO: in the backend release this should be wrapped in payload property first, to be consistent with the rest
  // of the actions
  return createAction(type, props<ClearFileInfoPayload>());
};

const createPrepareForBulkTransferAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: BulkFileOperationRequest }>());
};

export class DefaultFileActionConverter {
  constructor(
    private _actions: {
      cancelTransfer?: ActionCreator<any, (props: { abort?: any }) => { abort?: any } & Action<string>>;
      processFileFail?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      processFileSuccess?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      updateFileUploadProgress?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      clearFileInfoFromStore?: ActionCreator<any, (props: ClearFileInfoPayload) => ClearFileInfoPayload & Action<string>>;
      updateFileDownloadProgress?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      prepareForBulkTransfer?: ActionCreator<any, (props: { payload: BulkFileOperationRequest }) => { payload: BulkFileOperationRequest } & Action<string>>;
      previewFile?: ActionCreator<any, (props: { payload: FilePreviewRequest }) => { payload: FilePreviewRequest } & Action<string>>;
      previewFileClosed?: ActionCreator<any, (props: { payload: FilePreviewResponse }) => { payload: FilePreviewResponse } & Action<string>>;
      previewFileEdit?: ActionCreator<any, (props: { payload: FilePreviewResponse }) => { payload: FilePreviewResponse } & Action<string>>;
    }
  ) {}
  get cancelTransfer() {
    return this._actions.cancelTransfer;
  }
  get processFileFail() {
    return this._actions.processFileFail;
  }
  get processFileSuccess() {
    return this._actions.processFileSuccess;
  }
  get updateFileUploadProgress() {
    return this._actions.updateFileUploadProgress;
  }
  get clearFileInfoFromStore() {
    return this._actions.clearFileInfoFromStore;
  }
  get updateFileDownloadProgress() {
    return this._actions.updateFileDownloadProgress;
  }
  get prepareForBulkTransfer() {
    return this._actions.prepareForBulkTransfer;
  }
  get previewFile() {
    return this._actions.previewFile;
  }
  get previewFileClosed() {
    return this._actions.previewFileClosed;
  }
  get previewFileEdit() {
    return this._actions.previewFileEdit;
  }
}

export const populateDefaultFileActions = (actionMap: Map<number, string>) =>
  new DefaultFileActionConverter({
    cancelTransfer: createCancelTransferAction(actionMap.get(FileOperationActions.CancelTransfer)),
    clearFileInfoFromStore: createClearFileInfoAction(actionMap.get(FileOperationActions.ClearFileInfoFromStore)),
    prepareForBulkTransfer: createPrepareForBulkTransferAction(actionMap.get(FileOperationActions.PrepareForBulkTransfer)),

    processFileFail: createProcessFileFailAction(actionMap.get(FileOperationActions.ProcessFileFail)),
    processFileSuccess: createProcessFileSuccessAction(actionMap.get(FileOperationActions.ProcessFileSuccess)),
    updateFileDownloadProgress: createUpdateFileProgressAction(actionMap.get(FileOperationActions.UpdateFileDownloadProgress)),
    updateFileUploadProgress: createUpdateFileProgressAction(actionMap.get(FileOperationActions.UpdateFileUploadProgress))
  });

export class SafariReduxFileTransferObjectDefinition {
  constructor(
    private _fileActions: DefaultFileActionConverter,
    private _reducer: ActionReducer<any, Action>,
    private _fileSelectors: DefaultFileSelectorConverter
  ) {}

  get default() {
    return {
      actions: this._fileActions,
      selectors: this._fileSelectors
    };
  }
  get reducer() {
    return this._reducer;
  }
}

export class DefaultFileSelectorConverter {
  constructor(
    private _selectors: {
      fileOperations?: MemoizedSelector<object, FileOperationInfo[], DefaultProjectorFn<FileOperationInfo[]>>;
    }
  ) {}
  get fileOperations() {
    return this._selectors.fileOperations;
  }
}
