import { Location } from '@angular/common';
import { Directive, OnDestroy, OnInit } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Company, User } from '@safarilaw-webapp/feature/co-manage/data-access';
import { AuthService } from '@safarilaw-webapp/shared/auth';
import { IdName, SafariInjector } from '@safarilaw-webapp/shared/common-objects-models';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { dataAccessMixin } from '@safarilaw-webapp/shared/redux';
import { Observable, Subscription } from 'rxjs';
import { AppUiReduxObject } from '../../../state/actions/ui-actions';
import { NavbarGroup } from '../../models/navbar-group';
import { NavbarHeader } from '../../models/navbar-header';
import { NavbarItem } from '../../models/navbar-item';
import { NavbarService } from '../../services/navbar/navbar.service';

/**
 * Base for navbar component.
 * DO NOT forget to declare the child in app.module of the app
 */

@Directive({
  // navbar component inherits from dataAccessMixin that doesn't have any decorators
  // but this component also implements OnDestroy which apparently makes ivy think it
  // needs a selector for some reason, so here it is....
  // eslint-disable-next-line @angular-eslint/directive-selector -- comments above
  selector: 'make-angular-happy'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- ignore NG suggestions
export class NavbarComponent extends dataAccessMixin(SafariInjector) implements OnInit, OnDestroy {
  protected _store: Store<any>;
  protected _actions: Actions;
  public auth: AuthService;
  private _navbarService: NavbarService;
  private _location: Location;

  private _featureflagservice: FeatureFlagsService;
  private _appUiReduxObject: AppUiReduxObject;
  protected _authService: AuthService;

  public navbarGroups: NavbarGroup[] = [];
  public navbarHeaders: NavbarHeader[] = [];
  public navbarSubItems: NavbarItem[] = [];

  private _navbarActiveSub: Subscription = null;
  private _navbarServiceSub: Subscription = null;
  company$: Observable<Company>;
  user$: Observable<User>;
  active = false;

  allowNavigation$: Observable<boolean> = null;
  itemBadges$: Observable<IdName[]> = null;

  constructor() {
    super();

    this._store = this.inject(Store);
    this._actions = this.inject(Actions);
    this.auth = this.inject(AuthService);
    this._navbarService = this.inject(NavbarService);
    this._location = this.inject(Location);
    this._featureflagservice = this.inject(FeatureFlagsService);
    this._appUiReduxObject = this.inject(AppUiReduxObject);
    this._authService = this.inject(AuthService);
  }

  showCompanyName() {
    return this._featureflagservice.devTools || this._authService.hasMultipleCompanies;
  }
  isNavbarGroup(obj: any): obj is NavbarGroup {
    if (obj instanceof NavbarGroup) {
      return true;
    }
    return false;
  }

  isNavbarHeader(obj: any): obj is NavbarHeader {
    if (obj instanceof NavbarHeader) {
      return true;
    }
    return false;
  }
  isNavbarItem(obj: any): obj is NavbarItem {
    if (obj instanceof NavbarItem) {
      return true;
    }
    return false;
  }
  toggleSidebar() {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleNavbarActive());
  }
  ngOnInit() {
    // TODO (backend release): This should really move out of navbar if possible and into maybe app.component.ts

    this.reloadItems();
    this.allowNavigation$ = this._store.pipe(select(this._appUiReduxObject.default.selectors.getIsNavigationAllowed));
    this.itemBadges$ = this._store.pipe(select(this._appUiReduxObject.default.selectors.getitemBadges));
    this._navbarActiveSub = this._store.pipe(select(this._appUiReduxObject.default.selectors.getNavbarActive)).subscribe(navbarActive => (this.active = navbarActive));
  }
  itemBadgeValue(id: string, itemBadges: IdName[]) {
    const badge = itemBadges.find(o => o.id == id);
    if (badge != null) {
      return badge.name;
    }
    return null;
  }
  reloadItems() {
    const navbargroupskey = 'safari_navbar_navbargroups';
    const navbarheaderskey = 'safari_navbar_headers';
    const navbarsubitemskey = 'safari_navbar_navbarsubitems';

    const navbarGroupsFromStorage = localStorage.getItem(navbargroupskey);
    const navbarHeadersFromStorage = localStorage.getItem(navbarheaderskey);
    const navbarSubItemsFromStorage = localStorage.getItem(navbarsubitemskey);

    if (navbarGroupsFromStorage != null) {
      this.navbarGroups = JSON.parse(navbarGroupsFromStorage);
    }
    if (navbarHeadersFromStorage != null) {
      this.navbarHeaders = JSON.parse(navbarHeadersFromStorage);
    }
    if (navbarSubItemsFromStorage != null) {
      this.navbarSubItems = JSON.parse(navbarSubItemsFromStorage);
    }

    this._navbarServiceSub = this._navbarService.getNavbarItems().subscribe(allItems => {
      this.navbarGroups = allItems.filter(o => this.isNavbarGroup(o)) as NavbarGroup[];
      localStorage.setItem(navbargroupskey, JSON.stringify(this.navbarGroups));

      this.navbarHeaders = allItems.filter(o => this.isNavbarHeader(o));
      localStorage.setItem(navbarheaderskey, JSON.stringify(this.navbarHeaders));

      this.navbarSubItems = allItems.filter(o => this.isNavbarItem(o));
      localStorage.setItem(navbarsubitemskey, JSON.stringify(this.navbarSubItems));
    });
  }
  getChildId(id: number) {
    return `s_mnu-link-child-${id}`;
  }
  getId(id: number): string {
    return `s_mnu-link-parent-${id}`;
  }
  getNavbarHeaders(groupId: number) {
    return this.navbarHeaders.filter(o => o.groupId === groupId);
  }
  getNavbarItems(headerId: number) {
    return this.navbarSubItems.filter(o => o.parentId === headerId);
  }
  hasNavbarHeaders() {
    return this.navbarHeaders && this.navbarHeaders.length > 0;
  }
  toggleExpand(header: NavbarHeader) {
    header.expanded = !header.expanded;
  }
  hasGroupTitle(group: NavbarGroup) {
    return group.title !== '';
  }
  hasHeaderLink(header: NavbarHeader) {
    return header.link !== '';
  }
  isHeaderActive(id: number): boolean {
    const url = this._location.path();
    const routerLink = url.split('?')[0];
    const menuItem = this.navbarHeaders.find(item => item.id === id);
    return (
      menuItem &&
      /* #6287 - Even though the navbar header items will always have at least an empty array for activeChildPaths,
       * because we cache them locally to optimize loading time the old data is stale and activeChildPaths will be undefined. */
      ((menuItem.link && routerLink.indexOf(menuItem.link) === 0) || (menuItem.activeChildPaths?.length > 0 && menuItem.activeChildPaths?.any(childPath => routerLink.indexOf(childPath) === 0)))
    );
  }
  isActive(id: number): boolean {
    const url = this._location.path();
    const routerLink = url.split('?')[0];
    const menuItems = this.navbarSubItems.filter(item => item.id === id || item.parentId === id).filter(item => routerLink.indexOf(item.link) === 0);
    return menuItems.length > 0;
  }
  isSubheaderActive(relativePath: string): boolean {
    const url = this._location.path();
    const routerLink = url.split('?')[0];
    return routerLink.toLowerCase().endsWith(relativePath.toLowerCase());
  }
  isDivider(item: NavbarItem) {
    return item.isDivider;
  }
  getSubItemId(link: string) {
    const id = link.replace(/\//g, '-');
    if (id.startsWith('-')) {
      return id.substring(1);
    }
    return id;
  }
  ngOnDestroy(): void {
    if (this._navbarActiveSub != null) {
      this._navbarActiveSub.unsubscribe();
    }
    if (this._navbarServiceSub != null) {
      this._navbarServiceSub.unsubscribe();
    }
  }
}
