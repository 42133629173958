<ng-container *ngIf="companyList">
    <h1 class="my-2 text-center">Choose a Company to login</h1>
    <div class="row justify-content-center">
      <div class="col-6">
        <div class="input-group">
          <input class="form-control" placeholder="Filter by company name" type="search" [(ngModel)]="searchCriteria">
        </div>
      </div>
    </div>
    <div class="card-container">
      <div *ngFor="let company of companyList" class="card company-card" (click)="selectCompany(company.id)">
        <img
          class="card-img-top p-3"
          [src]="getCompanyLogoUrl(company.id)"
        />
        <div class="card-body text-center">
          <h5 class="card-title">{{ company.name }}</h5>
          <!-- <p class="card-text">Some descriptive text on the card</p> -->
        </div>
      </div>    
      <div *ngIf="!companyList.length" class="empty-row">
        No active companies match your criteria.
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-link" [routerLink]="['/']">Back to Home</a>
        <a class="btn btn-link" [routerLink]="['/logout']">Sign Out</a>
      </div>
    </div>
</ng-container>